<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">

            <v-dialog v-model="snackbar" persistent max-width="500" >
                <v-card>
                    <v-card-text style="background-color: red" class="scrollable-content error">
                        <v-scroll-y style="max-height: 200px; max-width: 200px; overflow: hidden">
                            <div class="pt-5">
                            <p v-for="(item, index) in errorMessage" :key="index" style="color: white"> {{item}}</p>
                            </div>
                        </v-scroll-y>
                    </v-card-text>
                    <v-card-actions style="background-color: red" class="error">
                        <v-spacer></v-spacer>
                        <v-btn color="white" text @click="snackbar = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-row class="mx-5">
                <v-col :cols="5">
                    <v-file-input
                        v-model="sas_entity.file"
                        :accept="acceptedFormats"
                        prepend-icon="mdi-paperclip"
                        label="SOS Entity Update"
                        dense
                        outlined
                        hide-details="auto"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1">
                    <v-btn
                        v-if="sas_entity.file"
                        class="mr-4"
                        outlined
                        color="primary"
                        @click="uploadEntity">
                        Upload
                    </v-btn>
                    <v-btn
                        v-else
                        disabled
                        class="mr-4"
                        outlined
                        color="primary">
                        Upload
                    </v-btn>
                </v-col>

                <v-col :cols="5">
                    <v-file-input
                        v-model="sas_location.file"
                        :accept="acceptedFormats"
                        prepend-icon="mdi-paperclip"
                        label="SOS Location Update"
                        dense
                        outlined
                        hide-details="auto"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1">
                    <v-btn
                        v-if="sas_location.file"
                        class="mr-4"
                        outlined
                        color="primary"
                        @click="uploadLocation">
                        Upload
                    </v-btn>
                    <v-btn
                        v-else
                        disabled
                        class="mr-4"
                        outlined
                        color="primary">
                        Upload
                    </v-btn>
                </v-col>
            </v-row>


            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="sasItem"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.sp_primary_details.account_name`]="{item}">
                                <div style="cursor: pointer;" @click="$router.push({ path: 'sales-prospecting/view-profile/' + item.id})">
                                    {{item.sp_primary_details.account_name}}
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <div>

                                    <v-btn 
                                        text
                                        color="primary"
                                        small
                                        >
                                        <SasDialog :item="item"></SasDialog>
                                    </v-btn>

                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'
import SasDialog from '../components/modules/salesProspecting/SasDialog.vue';
import jsonToFormData from 'json-form-data';
export default {
  name: 'Users',
  data () {
    return {
        search: '',
        salesProspecting: [],
        sasItem: [],
        headers: [
            {
                text: 'Account Name',
                sortable: true,
                value: 'sp_primary_details.account_name'
            },
            {
                text: 'BP ID Number',
                sortable: true,
                value: 'bp_idno'
            },
            {
                text: 'SP Number',
                sortable: true,
                value: 'prospective_number'
            },
            {
                text: 'Owner',
                sortable: true,
                value: 'created_by'
            },
            {
                text: 'Date Created',
                sortable: true,
                value: 'created_at'
            },
            {
                text: 'Profile',
                sortable: true,
                value: 'profile'
            },
            {
                text: 'Approval Status',
                sortable: true,
                value: 'status'
            },
            
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
        acceptedFormats: '.csv',
        sas_entity: {
            file: null
        },
        sas_location: {
            file: null
        },
        snackbar: false,
        errorMessage: [],
    }
  },
  components:{
        SasDialog: SasDialog,
    },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  async mounted () {
    this.reloadDetails()
  },
  methods: {
    async reloadDetails(){
        const res = await this.$store.dispatch('salesProspecting/doGetAllListSalesProspecting')
        this.salesProspecting = res.data.result
        console.log(this.salesProspecting)

        this.salesProspecting.map(item => {
            if (item.profile == 'complete' && item.sp_branch.length != 0 && item.sp_other_primary_details != undefined){
                this.sasItem.push(item)
            }
        })

        this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM')
        this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM_INITIAL_UPLOAD')
        this.$store.commit('salesProspecting/SET_PRIMARY_BUSINESS_ADDRESS_DEFAULT')
        this.$store.commit('salesProspecting/SET_PRIMARY_CONTACT_INFO_DEFAULT')
        this.$store.commit('salesProspecting/SET_COMPLIANCE_OFFICER_DEFAULT')
        this.$store.commit('salesProspecting/SET_OTHER_PRIMARY_DETAILS_DEFAULT')
        this.$store.commit('salesProspecting/SET_EXISTINGFILES_DEFAULT')
        this.$store.commit('salesProspecting/SET_RENEWABLE_FILES_DEFAULT')
        this.$store.commit('salesProspecting/SET_RENEWABLE_BRANCH_PERMIT_DEFAULT')
        this.$store.commit('salesProspecting/SET_RENEWABLE_OWNER_AMLA_DEFAULT')
        this.$store.commit('salesProspecting/SET_COMMENT_DEFAULT')
        this.$store.commit('salesProspecting/SET_FINAL_COMMENT_DEFAULT')
        this.$store.commit('salesProspecting/SET_EXISTING_WITNESS_DEFAULT')
        this.$store.commit('salesProspecting/SET_WITNESS_DEFAULT')
        this.$store.commit('salesProspecting/SET_EXISTING_BRANCH_DEFAULT')
        this.$store.commit('salesProspecting/SET_EXISTING_BSP_DEFAULT')
        this.$store.commit('salesProspecting/SET_SIGNATORY_DEFAULT')
        this.$store.commit('salesProspecting/SET_EXISTING_SIGNATORY_DEFAULT')
        this.$store.commit('salesProspecting/SET_EXISTING_BOD_DEFAULT')
        this.$store.commit('salesProspecting/SET_BUSINESSADD_CITY_DEFAULT')
        this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_ZIP_DEFAULT')
        this.$store.commit('salesProspecting/SET_BUSINESSADD_ZIP_DEFAULT')
        this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_CITY_DEFAULT')
        this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_CITY_DEFAULT')
        this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_ZIP_DEFAULT')
        this.$store.commit('salesProspecting/SET_COMPLIANCE_CITY_DEFAULT')
        this.$store.commit('salesProspecting/SET_COMPLIANCE_ZIP_DEFAULT')
        this.$store.commit('salesProspecting/SET_BRANCHES_CITY_DEFAULT')
        this.$store.commit('salesProspecting/SET_BRANCHES_ZIP_DEFAULT')
        this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_CITY_DEFAULT')
        this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_ZIP_DEFAULT')
        this.$store.commit('salesProspecting/SET_OWNERS_CITY_DEFAULT')
        this.$store.commit('salesProspecting/SET_OWNERS_ZIP_DEFAULT')
        this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_CITY_DEFAULT')
        this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_ZIP_DEFAULT')
        this.$store.commit('salesProspecting/SET_BOD_CITY_DEFAULT')
        this.$store.commit('salesProspecting/SET_BOD_ZIP_DEFAULT')
        this.$store.commit('salesProspecting/SET_EXISTINGBOD_CITY_DEFAULT')
        this.$store.commit('salesProspecting/SET_EXISTINGBOD_ZIP_DEFAULT')
        this.$store.commit('salesProspecting/SET_MOA_DEFAULT')
        this.$store.commit('bsp/UNSET_BSP_ID')
        this.$store.commit('bsp/SET_DET_DETAILS_DEFAULT')
        this.$store.commit('bsp/SET_DEF_FILES_DEFAULT')
        this.$store.commit('bsp/DET_FILES_DEFAULT')
        this.$store.commit('bsp/SET_COMMENT_DEFAULT')
        this.$store.commit('salesProspecting/SET_COMPLIANCE_RETURNED_COMMENTS_DEFAULT')
        this.$store.commit('salesProspecting/SET_ENCODER_RETURNED_COMMENTS_DEFAULT')
        this.$store.commit('salesProspecting/SET_COMPLIANCE_APPROVED_COMMENTS_DEFAULT')
        this.$store.commit('salesProspecting/SET_RADIO_DEFAULT')
    },
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
    },
    async uploadEntity(){
            const initialPayload = this.sas_entity
            var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(initialPayload, options)
			const payload = {payload: convertedFormData}
            const res = await this.$store.dispatch('salesProspecting/doUploadSasEntity', {payload})
            console.log(res)
            if (res.status == 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Uploaded Successfully','messagetype':'success'});
                this.setDefault()
                this.reloadDetails()
            } else if ((res.status === 206 || res.code === 400 || res.code === 422) && res.message == 'Import Validation Failures'){
                this.errorMessage = []
                this.errorMessage = res.error
                this.snackbar = true
                this.setDefault()
            } else if ((res.status === 206 || res.code === 400 || res.code === 422) && res.message == 'Bad Request'){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error,'messagetype':'error'});
                this.setDefault()
            } else if (res.status == 422){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error,'messagetype':'error'});
                this.setDefault()
            }  else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
                this.setDefault()
            }
        },
        async uploadLocation(){
            const initialPayload = this.sas_location
            var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(initialPayload, options)
			const payload = {payload: convertedFormData}
            const res = await this.$store.dispatch('salesProspecting/doUploadSasLocation', {payload})
            if (res.status == 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Uploaded Successfully','messagetype':'success'});
                this.setDefault()
                this.reloadDetails()
            } else if ((res.status === 206 || res.code === 400 || res.code === 422) && res.message == 'Import Validation Failures'){
                this.errorMessage = []
                this.errorMessage = res.error
                this.snackbar = true
                this.setDefault()
            } else if ((res.status === 206 || res.code === 400 || res.code === 422) && res.message == 'Bad Request'){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error,'messagetype':'error'});
                this.setDefault()
            } else if (res.code == 422){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error,'messagetype':'error'});
                this.setDefault()
            }  else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
                this.setDefault()
            }
        },
        cancelDialog(){
            this.dialog = false
            this.bp_idno = ''
        },
        openDialog(e){
            this.bp_idno = e.bp_idno
            this.dialog = true
        },
        setDefault(){
            this.sas_entity = {
                file: null
            }
            this.sas_location = {
                file: null
            }
        }
  }
}
</script>